import React from 'react'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { Link } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const Wallet = () => {
  return (
    <div className='wallet '>
      <div className="orderhistory   h-16 m-5 flex items-center justify-center font-light gap-3 text-xl" style={{ borderWidth: '1px' }}> 
        <HistoryOutlinedIcon  />
        <Link to="/paymenthistory">
        Order History
        </Link>
      </div>
      <hr />
      <div className='cards m-3 p-2 '>
         <div className="card1  rounded-md  mb-3  text-pretty" style={{borderWidth:"2px"}} >
           <div className='flex items-center justify-between p-4'> 
            <div>
            <span className='flex text-xl font-bold items-center'>
              <img src="https://cdn.pixabay.com/photo/2018/06/20/18/05/money-3487038_640.png" alt="" className='h-10 w-8' />
              ₹1</span>
                 <p>Deposit Cash</p>
            </div>
            <Link to="/payments">
              <button className="border pl-4 pr-4 p-2 border-black rounded-md font-bold">
                ADD CASH
              </button>
            </Link>
           </div>
           <div className='items-center flex justify-center p-2 text-sm  text-gray-500 text-wrap'>
           Can be used to play Tournaments & Battles.Cannot be withdrawn to Paytm or Bank
           </div>
         </div>

         <div className="card1  rounded-md  mb-3 " style={{borderWidth:"1px"}} >
           <div className='flex items-center justify-between p-4'> 
            <div>
            <span className='flex text-xl font-bold items-center'>
              <img src="https://cdn.pixabay.com/photo/2018/06/20/18/05/money-3487038_640.png" alt="" className='h-10 w-8' />
              ₹1</span>
                 <p>Winnings Cash</p>
            </div>
               <button className='border pl-4 pr-4 p-2 border-black rounded-md font-bold'>WITHDRAW</button>
           </div>
           <div className='items-center flex justify-center p-2 text-sm  text-gray-500 text-wrap'>
           Can be withdrawn to Paytm or Bank. Can be used to play Tournaments & Battles.
           </div>
         </div>

         <div className="card1  rounded-md  mb-3 " style={{borderWidth:"1px"}} >
           <div className='flex items-center justify-between p-4'> 
            <div>
            <span className='flex text-xl font-bold items-center'>
              <img src="https://cdn.pixabay.com/photo/2018/06/20/18/05/money-3487038_640.png" alt="" className='h-10 w-8' />
              ₹1</span>
                 <p>Cashback</p>
            </div>
               <button className='border pl-4 pr-4 p-2 border-black rounded-md font-bold'>PLAY GAMES</button>
           </div>
           <div className='items-center flex justify-center p-2 text-sm  text-gray-500 text-wrap'>
           Can be used to play Tournaments & Battles. Cannot be withdrawn to Paytm or Bank.
           </div>
         </div>

         

         
           
         </div>

    </div>
  )
}

export default Wallet
