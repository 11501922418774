/* global Razorpay */
import React, {useState} from 'react';
import api from '../api/api';

const Payment = () => {
  const [amount, setAmount] = useState('');
  const [success, setSuccess] = useState(false);

  const paymentHandler = async (e)=>{
    e.preventDefault();

    if(!amount || isNaN(amount)){
      alert( 'please enter a valid amount')
      return;
    }

    try{
      const {data: order} = await api.post('/payments/createorder',{
        amount:  parseFloat(amount),//convert amount to float
      })

      if(!order || !order.id){
        alert('Order creation failed Please try again');
        return
      }

      const options ={
        key:process.env.REACT_RAZORPAY_KEY_ID,
        amount : order.amount,
        currency:'INR',
        name: 'KhelBabu',
        decription: 'Payment transaction',
        image:"https://khelbabauassets.s3.eu-north-1.amazonaws.com/e-sports-6701392_1280.png",
        order_id: order.id,
        handler : async function (response){
            console.log('Payment response :', response);
            const {data : validation} =await api.post('/payments/verifypayment',{
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              amount: amount
            });
            console.log('Validation response', validation);
            console.log(validation.msg)

            if (validation ) {
              setSuccess(true); // Show success message
              setTimeout(() => {
                window.location.href = '/'; // Redirect to home page after 5 seconds
              }, 5000);
            } else {
              alert('Transaction validation failed.');
            }
          },
            prefill: {
              name: 'Your Customer Name', // Replace with customer details
              email: 'customer@example.com',
              contact: '9999999999',
            },
            notes: {
              address: 'Your Business Address',
            },
            theme: {
              color: '#3399cc',
            },
           };
           const rzp1 = new Razorpay(options);

           rzp1.on('payment.failed', function (response) {
             console.error('Payment failed:', response);
             alert('Payment failed. Please try again.');
             window.location.href = '/failure'; // Redirect to failure page
           });
     
           rzp1.open();
         } catch (error) {
           console.error('Error in payment handler:', error);
           alert('Something went wrong. Please try again.');
         }
       };
     
  return (
    <div className="product">
      {success ? (
        <div className="success-message">
          <h2>Payment Successful!</h2>
          <p>You will be redirected to the home page in 5 seconds.</p>
          <button onClick={() => (window.location.href = '/')}>Go to Home</button>
        </div>
      ) : (
        <form onSubmit={paymentHandler}>
          <h1>Razorpay Payment Gateway</h1>
          <div>
            <label htmlFor="amount">Amount: </label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              required
            />
          </div>
          <button type="submit" className="button">
            Pay Now
          </button>
        </form>
      )}
    </div>
  );
}



export default Payment
